import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import toolsHelper from "@/mixins/toolsHelper";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import neoSwitch from "@/components/toggle-switch";
import GoogleCard from "./google-card";
import SaasAxios from "@/axios";

export default {
    name: "neo-google",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        NeoAnalyse,
        "download-csv": JsonCSV,
        neoSwitch,
        GoogleCard,
    },
    mixins: [toolsHelper],
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            searchType: "email",
            inputValue: "Email",
            inputOptions: [
                {
                    group: "Field Type",
                    categories: ["Email", "Phone", "Google ID"],
                },
            ],
            emailList: [],
            phoneList: [],
            loaderController: {
                google: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            userDetails: {},
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            searchKeyword: "",
            selectedItem: null,
            selectedItemUsername: null,
            showResult: false,
            googleID: "",
            triggerRoute: true,
            searchedGoogleID: "",
            selectNameVal: "",
            selectVal: "",
            searchClicked: false,
            isMonitoringTab: false,
            monitoringUserDetails: [],
            tabName: "",
            request_id: "",
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseEmails", "getCasePhones", "getSocialPlatforms", "getMonitoringDateFilter", "getSelectedToolQuery", "getAllMonitoringData", "getProduct"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        query() {
            return this.selectedItem?.value || this.selectedItemUsername?.value;
        },

        csvData() {
            let data = [];
            if (this.isMonitoringTab) {
                data = this.monitoringUserDetails;
            } else {
                data.push(this.userDetails);
            }
            return this.convertToCSV(data);
        },

        csvFields() {
            return ["gid", "extra_email", "photos"];
        },

        validatePhone() {
            this.searchKeyword = this.searchKeyword.replaceAll(" ", "");
            this.searchKeyword = this.searchKeyword.substr(0, 1) + this.searchKeyword.substr(1).replaceAll("+", "");
            this.searchKeyword = this.searchKeyword.replace(/-|_|/gi, "");
            this.searchKeyword = this.searchKeyword.replaceAll("(", "");
            this.searchKeyword = this.searchKeyword.replaceAll(")", "");
            const phoneRegex = /^[+]{1}[0-9-]/;
            return phoneRegex.test(this.searchKeyword);
        },
        checkMarkAllDone() {
            if (this.monitoringUserDetails && this.monitoringUserDetails.length) return this.monitoringUserDetails.every((result) => result.changes_viewed);
            else return false;
        },
    },
    watch: {
        // userDetails() {
        //   if(this.userDetails) {
        //     this.$emit('searchResult', {data:this.userDetails, source:'google', searchType:'tool'});
        //   }
        // }
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // googleID() {
        //     this.showResult = false;
        // },

        // selectedItemUsername: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        this.isMonitoringTab = this.$route.name === "monitoring";
        const dataRes = this.getToolsData(this.tabName, "google-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        // this.SET_ALL_TOOLS_DATA({ ...this._data, "tool-name": "google-tool" });
        this.setToolsData(this.tabName, "google-tool");
    },
    methods: {
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAnalysisDropdownData", "getAllCaseEmails", "getAllCasePhones"]),

        async getToolData() {
            this.emailList = await this.getDropdownData("email");
            this.phoneList = await this.getDropdownData("phone");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "google", [...this.emailList, ...this.phoneList], data);
            allChecked = [...this.emailList, ...this.phoneList]?.length ? ([...this.emailList, ...this.phoneList].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        getPlatformIcon(platform) {
            let social = this.getSocialPlatforms.find((social) => social.key == platform);
            if (social) return social.icon_data;
            else {
                return require(`@/assets/icons/${platform}.svg`);
            }
        },

        setInput() {
            if (this.inputValue === "Email") {
                this.searchType = "email";
                this.searchKeyword = this.selectedItem.value;
            } else if (this.inputValue === "Phone") {
                this.searchType = "phone";
                this.searchKeyword = this.selectedItemUsername.value;
            }
            // this.search();
        },
        typeChanged() {
            this.searchKeyword = "";
            this.selectedItem = null;
            this.selectedItemUsername = null;
            this.googleID = "";
            this.searchedGoogleID = "";
            this.showResult = false;
        },

        setTheCustomInput(value) {
            if (this.inputValue === "Email") {
                this.searchType = "email";
                this.searchKeyword = value;
                this.selectedItem = {
                    value: value,
                };
            } else if (this.inputValue === "Phone") {
                this.searchType = "phone";
                this.searchKeyword = value;
                this.selectedItemUsername = {
                    value: value,
                };
            }
            // this.search();
        },

        manualSearch() {
            this.triggerRoute = false;
            this.userDetails = {};
            this.showResult = false;
            const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
            if (this.inputValue != "Google ID") {
                if (this.inputValue === "Phone" && this.validatePhone) this.search();
                else if (this.inputValue === "Phone" && !this.validatePhone) {
                    this.$toast.error("Please provide a valid phone number with country code");
                }
                if (this.inputValue === "Email" && emailRegex.test(this.searchKeyword.trim())) this.search();
                else if (this.inputValue === "Email" && !emailRegex.test(this.searchKeyword.trim())) this.$toast.error("Please enter a valid input");
            } else {
                this.searchedGoogleID = this.googleID;
                this.searchType = "googleID";
                this.search();
            }
        },

        async search() {
            this.showResult = false;
            if (this.googleID) {
                this.searchKeyword = this.googleID;
                this.searchedGoogleID = this.googleID;
            }

            if (this.searchKeyword) {
                // if (this.$route.query.q !== this.searchKeyword)
                this.searchClicked = true;
                if (this.isMonitoringTab) {
                    this.startLoader("google");
                    await this.monitoringSearch();
                    this.showResult = true;
                    this.resetLoader("google");
                    return;
                }
                this.$emit("searched", {data: {[this.searchType]: this.searchKeyword}, source: "google", searchType: this.searchType, type: this.searchType});
                await this.getGoogle();
                if (this.inputValue != "Google ID") {
                    await this.setSearchedgoogleUser(this.searchKeyword, this.inputValue === "Email" ? "email" : this.inputValue === "Phone" ? "phone" : "");
                }
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "google-tool"});
            } else {
                this.$toast.error("Please provide a valid input.");
            }
        },
        async monitoringSearch() {
            let sources = ["google-tool"];
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            if (this.inputValue !== "Google ID") {
                this.monitoringUserDetails = values;
            }
        },
        async setSearchedgoogleUser(searchKeyword, type) {
            let val_type = this.selectedItem?.val_type || this.selectedItemUsername?.val_type || type;
            const data = {
                value: searchKeyword,
                platform: "google",
                val_type: val_type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`${val_type}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`${val_type}List`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        async getGoogle(isNext) {
            this.startLoader("google");
            let data = {};
            let url = "";
            if (this.inputValue === "Email" || this.inputValue === "Phone") {
                url = "/tools/google";
            }

            if (this.inputValue === "Email") {
                data = {
                    email: this.searchKeyword.trim(),
                };
            } else if (this.inputValue === "Phone") {
                data = {
                    phone: this.searchKeyword,
                };
            } else if (this.inputValue === "Google ID") {
                data = {
                    query: this.searchKeyword,
                };
            }

            if (this.inputValue === "Email" || this.inputValue === "Phone") {
                await axios
                    .post(url, data, {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    })
                    .then((response) => {
                        if (response && response.data && response.data) {
                            this.userDetails = response.data;
                            this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "google-tool"});
                            this.$emit("searchResult", {data: this.userDetails, source: "google", searchType: this.searchType, type: this.searchType});
                            this.showResult = true;
                        } else if (response) {
                            this.$toast.error("No data found");
                        }
                        this.request_id = response?.data?.query_id;
                        this.redirectTo();
                        this.triggerRoute = true;
                        this.resetLoader("google");
                    })
                    .catch((error) => {
                        this.resetLoader("google");
                        this.triggerRoute = true;
                        let message = "Please enter a valid input";
                        if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                        this.$toast.error(message);
                    });
            } else {
                let response = await axios.post("/tools/query", data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });
                this.request_id = response?.data?.query_id;
                this.redirectTo();
                this.resetLoader("google");
                this.showResult = true;
            }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                const type = Object.keys(selected_query?.query)[0];
                if (type === "email") {
                    this.searchKeyword = Object.values(selected_query?.query)[0];
                    this.selectedItem = {
                        value: Object.values(selected_query?.query)[0],
                    };
                    this.inputValue = "Email";
                    this.selectVal = Object.values(selected_query?.query)[0];
                } else if (type === "phone") {
                    this.searchKeyword = Object.values(selected_query?.query)[0];
                    this.selectedItemUsername = {
                        value: Object.values(selected_query?.query)[0],
                    };
                    this.inputValue = "Phone";
                    this.selectNameVal = Object.values(selected_query?.query)[0];
                } else if (type === "query") {
                    this.googleID = Object.values(selected_query?.query)[0];
                    this.inputValue = "Google ID";
                }

                this.searchType = type !== "query" ? type : "googleID";
            } else {
                this.redirectTo();
            }
        },
        onSelect(event, type) {
            if (type == "phone") {
                this.selectNameVal = event.value;
                this.$refs["analysis-tool-input"].search = event.value;
            } else {
                this.selectVal = event.value;
                this.$refs["analysis-tool-input"].search = event.value;
            }
        },

        close(type) {
            // this.searchType = "email";
            // this.searchKeyword = value;
            if (type == "phone") {
                this.selectedItemUsername = {
                    value: this.selectNameVal,
                };
                this.searchType = "phone";
                this.searchKeyword = this.selectNameVal;
                this.$refs["analysis-tool-input"].search = this.selectNameVal;
            } else {
                this.selectedItem = {
                    value: this.selectVal,
                };
                this.searchType = "email";
                this.searchKeyword = this.selectVal;
                this.$refs["analysis-tool-input"].search = this.selectVal;
            }
        },
        handleChange(event, type) {
            if (type == "phone") {
                if (event != this.selectNameVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectNameVal = event;
            } else {
                if (event != this.selectVal) {
                    this.showResult = false;
                    this.searchClicked = false;
                }
                this.selectVal = event;
            }
            // this.$set(this.selectVal, type,event)
        },
        onSelectFieldType() {
            this.selectedItem = null;
            this.selectedItemUsername = null;
            this.selectVal = "";
            this.selectNameVal = "";
            if (this.$refs["analysis-tool-input"]) {
                this.$refs["analysis-tool-input"].search = "";
            }
        },
        onInputGoogle() {
            this.showResult = false;
            this.searchClicked = false;
        },
        googleCond() {
            return !this.isMonitoringTab && this.searchKeyword && this.userDetails && this.userDetails.gid && this.showResult && this.inputValue !== "Google ID";
        },
        googleCondMonitoring() {
            return this.monitoringUserDetails.length > 0 && this.isMonitoringTab && this.searchKeyword && this.showResult && this.inputValue !== "Google ID";
        },
        async handleMarkAllAsDone({target}) {
            let sources = ["google-tool"];
            this.monitoringUserDetails.forEach((_, index) => {
                this.monitoringUserDetails[index].changes_viewed = target.checked;
            });
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources,
                mark_all_as_visited: target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        async handleDone({target}, index) {
            let sources = ["google-tool"];
            this.monitoringUserDetails[index].changes_viewed = target.checked;
            let doc_id = this.monitoringUserDetails[index].monitoring_id;
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id,
                visited: target.checked,
            });
        },
        async handleMonitoring(index) {
            this.monitoringUserDetails[index].monitoring_status = !this.monitoringUserDetails[index].monitoring_status;
            let card_id = this.monitoringUserDetails[index].doc_id;
            let card_source = "google-tool";
            let status = this.monitoringUserDetails[index].monitoring_status ? "enabled" : "disabled";
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source,
                status,
            });
        },
    },
};
