import NeoAnalyse from "@/components/analyse";
import {getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import neoSwitch from "@/components/toggle-switch";
import {DateTime} from "luxon";

export default {
    name: "google-card",
    components: {NeoAnalyse, neoSwitch},
    props: {
        userDetails: {
            type: Object,
            default: {},
        },
        isMonitoringTab: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        handleDone: {
            type: Function,
            default: () => 1,
        },
        handleMonitoring: {
            type: Function,
            default: () => 1,
        },
    },
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {
        getBackgroundStatus,
        getResultsID,
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
